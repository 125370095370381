.app-container {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
}

.title {
    align-items: center;
    font-size: x-large;
}

.date-text {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    font-size: larger;
    color: rgb(223, 138, 138);
} 

.task {
    width: 10.4vw; /* Set width as needed */
    height: 12vh; /* Set height as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2.5px solid var(--border-color, #ddd); /* Border color */
    border-radius: 8px; /* Adjust border-radius for rounded corners */
    padding: 10px; /* Add padding for space inside the border */
    margin: 10px; /* Add margin for spacing between tasks */
    font-size:smaller;
    white-space: pre-wrap;
}

.task-container {
    width: 100vw;
    height: 88vh;
    display: flex;
    flex-direction: row;
}

.dialogue-container {
    display: flex;
    flex-direction: column;
    bottom: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #ccc;
    border-radius: 8px;
    width: 11vw;
    align-items: center;
    justify-content: center;
}
